import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SettingsService } from './settings.service';

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    // sesion de usuario
    private session: Session = {
        token: null,
        refreshToken: null,
        recordarCuenta: null,
        usuario: '',
        nombre: '',
        apellido: '',
        fotoPerfilUrl: null,
        email: '',
        personaActiva: false,
    };

    // controla si el usuario tiene una persona asociada, se utiliza para bloquear los tabs
    userHasSession = new BehaviorSubject<boolean>(false);

    // persona verificada o persona activada (si esta verificada puede solicitar credito e ir al inicio)
    userHasVerfied = new BehaviorSubject<boolean>(false);

    // limpia el menu lateral seleccionado
    clearMenu = new BehaviorSubject<boolean>(false);

    // register notificacion id para notificaciones push
    registerId: string = null;

    // objeto que guarda envío de datos de sesión
    loginData = null;

    constructor(
        private storage: Storage,
        private router: Router,
        private http: HttpClient,
        private settings: SettingsService,
    ) {

    }

    async restoreSessionFromStorage() {
        await this.storage.get('token').then( token => { this.session.token = token});
        this.session.refreshToken = await this.storage.get('refreshToken').then( refreshToken => refreshToken);
        this.session.recordarCuenta = await this.storage.get('recordarme').then( recordarCuenta => recordarCuenta);
        this.session.usuario = await this.storage.get('usuario').then( usuario => usuario);
        this.session.fotoPerfilUrl = await this.storage.get('fotoPerfilUrl').then( fotoPerfilUrl => fotoPerfilUrl);
        this.session.nombre = await this.storage.get('nombre').then( nombre => nombre);
        this.session.apellido = await this.storage.get('apellido').then( apellido => apellido);
        this.session.email = await this.storage.get('email').then( email => email);
        this.session.personaActiva = await this.storage.get('personaActiva').then( personaActiva => personaActiva);

        console.log('## Sesion de usuario');
        console.log(this.session);

        this.userHasSession.next(true);

        if (this.session.personaActiva) {
            this.userHasVerfied.next(true);
        }

        if (!this.session.token) {
            console.log('## No existe token almacenado');
            this.userHasSession.next(false);
            this.router.navigate(['bienvenido-slide']);
        } else {
            console.log('## Existe token almacenado');
            if (this.session.personaActiva === true) {

                this.router.navigate(['/menu/inicio']);
            } else {
                this.router.navigate(['/perfil-usuario']);
            }

        }

    }

    /**
     * Almacena la sesion en memoria
     * @param data datos de sesion de usuario
     * @param recordarme true/false para recordar sesion de usuario
     */
    setSession(data: Session, recordarme: boolean = false) {
        console.log('Agregando sesion de usuario.');
        this.session = data;
        this.session.recordarCuenta = recordarme;
        this.userHasSession.next(true);
    }

    existSession() {
        return this.session.token;
    }

    /**
     * Retorna el token de acceso del usuario
     */
    public getAccessToken(): string {
        return this.session.token;
    }

    public getUsuario(): any {
        return {
            nombre: this.session.nombre,
            apellido: this.session.apellido,
            fotoPerfil: this.session.fotoPerfilUrl,
            documento: this.session.usuario,
            personaActiva: this.session.personaActiva,
        };
    }

    public async updateUserMenuInfo(nombre: '', apellido: '', fotoPerfilUrl: any) {
        this.session.nombre = nombre;
        this.session.apellido = apellido;
        this.session.fotoPerfilUrl = fotoPerfilUrl;

        await this.storage.set('nombre', nombre);
        await this.storage.set('apellido', apellido);

        if (fotoPerfilUrl) {
            await this.storage.set('fotoPerfilUrl', fotoPerfilUrl);
        }

        // al emitir el siguiente valor de observable de actualiza datos del menu lateral.
        this.userHasSession.next(true);

    }

    /**
     * Actualiza el token/sesion del usuario
     */
    public refreshToken(): Observable<any> {

        console.log('Actualizando accessToken...');

        const data = {
            refreshToken: this.session.refreshToken
        };

        return this.http.post(`${this.settings.URL_BASE}token/refresco`, data).pipe( tap( (resp: HttpResponse<any>) => {
            this.updateAccessToken(resp);
        }));
    }

    async updateAccessToken(data: any): Promise<any> {
        this.session.token = data.token;
        return await this.storage.set('token', data.token).then( () => {
            console.log('Token actualizado en memoria y storage');
            return true;
        });
    }

    public async clearStorage() {
        this.session.token = null;
        this.session.refreshToken = null;

        // se emite valor de sesión false para los componentes
        this.userHasSession.next(false);
        await this.storage.remove('token');
        await this.storage.remove('refreshToken');
        await this.storage.remove('personaActiva');
        await this.storage.remove('nombre');
        await this.storage.remove('apellido');
        await this.storage.remove('fotoPerfilUrl');

        console.log(this.session.recordarCuenta);
        if (!this.session.recordarCuenta) {
            await this.storage.remove('usuario');
        }
        console.log('??? El usuario es: ', this.session.usuario);

        this.resetUserInMemory();
    }

    private resetUserInMemory() {
        this.session = {
            token: null,
            refreshToken: null,
            recordarCuenta: this.session.recordarCuenta,
            usuario: '',
            nombre: '',
            apellido: '',
            fotoPerfilUrl: null,
            email: '',
            personaActiva: false,
        };
    }

    public getPublicKey(): Observable<any> {
        return this.http.get(`${this.settings.URL_BASE}clave/publica`);
    }

    /**
     * Envia una peticion http, utilizada para reenviar peticiones de manera transparente
     * cuando falla alguna llamada y se quiere un reenvio de la misma
     * @param request la peticion a enviar
     */
    public sendRequest(request: HttpRequest<any>): Observable<any> {
        return this.http.request(request);
    }

    /**
     * Verifica si el token actual es valido o invalido
     */
    public verificarToken(): Observable<any> {
        return this.http.get(`${this.settings.URL_BASE}token/validar`);
    }

}

export interface Session {
    token: string;
    refreshToken: string;
    usuario: string;
    recordarCuenta: boolean;
    nombre: string;
    apellido: string;
    fotoPerfilUrl: string;
    email: string;
    personaActiva: boolean;
}
