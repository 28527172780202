import { Injectable } from '@angular/core';
import { PermissionsOptions, PermissionType, Plugins } from '@capacitor/core';
import { BehaviorSubject } from 'rxjs';
import { AlertService } from './alert.service';
import { MensajesService } from './mensajes.service';

const { Device, Geolocation, Permissions } = Plugins;

@Injectable({
  providedIn: 'root',
})
export class DeviceService {

  static PANTEON_HEORES = {
    lat: -25.282329,
    long: -57.63513
  }

  // informacion del dispositivo
  deviceInfo = null;

  // nivel de bateria del dispositivo
  batteryInfo = null;

  // posicionamiento del usuario
  geolocation = {
    lat: DeviceService.PANTEON_HEORES.lat,
    long: DeviceService.PANTEON_HEORES.long
  };

  successGeolocation = new BehaviorSubject<boolean>(false);

  permisos = [];

  constructor(
    private alertService: AlertService,
    private mensajesSvc: MensajesService,
  ) {

    this.permisos = [
      { acceso: false, descripcion: 'Acceso a Cámara', name: PermissionType.Camera },
      { acceso: false, descripcion: 'Acceso a Notificaciones Push', name: PermissionType.Notifications },
      { acceso: false, descripcion: 'Acceso a mi ubicación', name: PermissionType.Geolocation },
      { acceso: false, descripcion: 'Acceso a Galería', name: PermissionType.Photos },
      // { acceso: false, descripcion: 'Micrófono', name: PermissionType.Microphone },
      // { acceso: false, descripcion: 'Lectura portapapeles', name: PermissionType.ClipboardRead },
      // { acceso: false, descripcion: 'Escritura portapapeles', name: PermissionType.ClipboardWrite },
    ];

    // datos del dispositivo
    Device.getInfo().then( info => {
      this.deviceInfo = info;
    }).catch( error => {
      console.log('No se pudo recuperar informacion del dispositivo');
      console.log(error);
    });

    this.updateBatteryInfo();

    console.log('Geolocation primera vez');
    this.updateGeolocation();
  }

  // se actualiza el nivel de bateria
  updateBatteryInfo() {
    Device.getBatteryInfo().then( info => {
      this.batteryInfo = info;
    }).catch( error => {
      console.log('No se pudo recuperar informacion de bateria');
      console.log(error);
    });
  }

  /**
   * Actualiza la posición del usuario
   */
  updateGeolocation() {
    console.log('Actualizando la ubicacion de usuario');
    Geolocation.getCurrentPosition({enableHighAccuracy: true}).then( data => {
      console.log('Ubicacion de usuario update');
      console.log(data);
      this.geolocation.lat = data.coords.latitude;
      this.geolocation.long = data.coords.longitude;
    }).catch( async error => {
      console.log('No se pudo recuperar ubicacion de usuario update');
      this.geolocation.lat = DeviceService.PANTEON_HEORES.lat;
      this.geolocation.long = DeviceService.PANTEON_HEORES.long;
      console.log(error);      
    });
  }

  async geolocationAndPermission() {

    const opts: PermissionsOptions = {
      name: PermissionType.Geolocation,
    }
    const gpsPermissionGranted = await Permissions.query(opts).then( data => {
      console.log(data);
      return data;
    }).catch( data => {
      return data;
    });

    await Geolocation.getCurrentPosition({enableHighAccuracy: true}).then( data => {
      console.log('Ubicacion de usuario updatePermission');
      console.log(data);
      this.geolocation.lat = data.coords.latitude;
      this.geolocation.long = data.coords.longitude;
      this.successGeolocation.next(true);
    }).catch( async error => {
      console.log('No se pudo recuperar ubicacion de usuario updatePermission');
      this.geolocation.lat = DeviceService.PANTEON_HEORES.lat;
      this.geolocation.long = DeviceService.PANTEON_HEORES.long;
      console.log(error);

      console.log('Resultado', gpsPermissionGranted);
      if (gpsPermissionGranted['state'] !== 'granted') {
        this.alertService.showMessage(this.mensajesSvc.mensajes.permisoGpsMsg.titulo, this.mensajesSvc.mensajes.permisoGpsMsg.mensaje);
      } else {
        this.alertService.showMessage(this.mensajesSvc.mensajes.activarGpsMsg.titulo, this.mensajesSvc.mensajes.activarGpsMsg.mensaje);
      }
    });

    this.updatePermissions();
    
  }

  async updatePermissions () {
    for (let index = 0; index < this.permisos.length; index++) {
      await Permissions.query({ name: this.permisos[index].name }).then( (data: any) => {
        this.permisos[index].acceso = data.state;
      });
    }
  }

  getPermisos() {
    console.log(this.permisos);
    return this.permisos;
  }

}
