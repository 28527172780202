import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  isLoading = false;
  showLoading = true;

  constructor(
    public loadingController: LoadingController,
    private toastService: ToastService,
  ) { }

  /**
   * Muestra un loader en pantalla
   * @param message mensaje para el loader
   * @param duration duracion del loader
   */
  async present(message: string = 'Favor espere..', duration: number = 15000) {

    // this.loaderTimeOutCheck(duration);

    this.isLoading = true;

    return await this.loadingController.create({
      message,
      duration,
      cssClass: 'custom-spinner-class'
    }).then(a => {
      a.present().then(() => {
        // console.log('..loader presentado');
        if (!this.isLoading) {
          a.dismiss().then(() => {
            // console.log('..abortando carga de loader')
          });
        }
      });
    });
  }

  async dismiss() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => {
      // console.log('..cerrando loader')
    }).catch( error => {
      // console.log('..No existe loading a cerrar');
    });
  }

  /**
   * Evita que el loader siga cargando en caso
   * de que haya ocurrido algun error inesperado
   * y no se haya cerrado
   * @param duration tiempo en ms que el loader puede mostrarse antes de cerrarse
   */
  loaderTimeOutCheck(duration: number) {
    setTimeout( () => {
      if (this.isLoading) {
        console.log('Loader finalizado por tiemeout');
        // this.toastService.showMessage('Operacion NO completada, intente más tarde', 3);
        this.dismiss();
      }
    }, duration);
  }
}
