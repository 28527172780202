import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SettingsService } from './settings.service';
import { catchError, map, finalize } from 'rxjs/operators';
import { from } from 'rxjs';
import { HTTP } from '@ionic-native/http/ngx';
import { SessionService } from './session.service';
import { DeviceService } from './device.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    userHasPerson = new BehaviorSubject<boolean>(false);
    updateProfileStatus = new BehaviorSubject<boolean>(false);
    seccionDatosTab = new BehaviorSubject<string>('DATOS_PERSONALES');

    // se mantiene en memoria el estado del perfil de usuario
    estadoPerfil = {
        datosPersonales: {
          cargado: true,
          mensaje: '',
        },
        datosLaborales: {
          cargado: true,
          mensaje: '',
        },
        documento: {
          cargado: true,
          mensaje: '',
        },
        direccionParticular: {
          cargado: true,
          mensaje: '',
        },
        direccionLaboral: {
          cargado: true,
          mensaje: '',
        },
        telefonoParticular: {
          cargado: true,
          mensaje: '',
        },
        telefonoLaboral: {
          cargado: true,
          mensaje: '',
        },
        legajo: {
          cargado: true,
          mensaje: '',
        },
    };

    constructor(
        private http: HttpClient,
        private settings: SettingsService,
        private httpNative: HTTP,
        private sessionService: SessionService,
        private deviceService: DeviceService
    ) {

    }


    /**
     * Registro de usuario
     * @param data
     */
    singUp(data: Register): Observable<any> {
        data.aplicacionId = this.settings.getAppId();
        return this.http.post(this.settings.URL_BASE + 'usuario', data);
    }

    /**
     * Retorna los datos de persona asociados al usuario
     */
    getProfile(): Observable<any> {
        return this.http.get(this.settings.URL_BASE + 'usuario/perfil');
    }

    updateProfileWeb(data: any, photo: any): Observable<any> {
        return this.http.post(this.settings.URL_BASE + 'usuario/perfil?username=' + data.username
        + '&email=' + data.email + '&fecha_nacimiento=' + data.fecha_nacimiento + '&persona=' + data.persona
        , photo);
    }

    /**
     * Actualiza los datos personales del usuario
     * @param body datos usuario/persona
     * @param filePath archivo a enviar
     */
    updateProfile(body: any, filePath: string) {

        let nativeCall = this.httpNative.uploadFile(
          `${this.settings.URL_BASE}usuario/perfil`,
          body,
          {
              'Authorization': `Bearer ${this.sessionService.getAccessToken()}`,
              'deviceInfo': JSON.stringify(this.deviceService.deviceInfo),
              'batteryInfo': JSON.stringify(this.deviceService.batteryInfo),
              'geolocation': JSON.stringify(this.deviceService.geolocation),
          },
          filePath,
          'fotoPerfil'
        );

        console.log('datos_personales');
        console.log(body);
        console.log(nativeCall);

        return from(nativeCall);

    }

    /**
     * Recupera un listado de documentos de usuario
     */
    getDocumento(): Observable<any> {
        return this.http.get(this.settings.URL_BASE + 'usuario/perfil/documento');
    }

    /**
     * Recupera estado de avance del perfil
     */
    getDetallePerfil(): Observable<any> {
        return this.http.get(this.settings.URL_BASE + 'usuario/perfil/progreso');
    }

    /**
     * Recupera datos laborales del usuario
     */
    getDatosLaborales(): Observable<any> {
        return this.http.get(this.settings.URL_BASE + 'usuario/perfil/laboral');
    }

    /**
     * Recupera las antiguedades laborales
     */
    getAntiguedadesLaborales(): Observable<any> {
        return this.http.get(this.settings.URL_BASE + 'datos/laborales/antiguedad');
    }

    /**
     * Recupera las ocupaciones laborales
     */
    getOcupacionesLaborales(): Observable<any> {
        return this.http.get(this.settings.URL_BASE + 'datos/laborales/ocupacion');
    }

    /**
     * Actualiza los datos laborales del usuario
     * @param body objeto con campos laborales del usuario
     */
    updateDatosLaborales(body: any): Observable<any> {
        return this.http.post(this.settings.URL_BASE + 'usuario/perfil/laboral', body);
    }

    /**
     * Recupera los estados de completitud para cada seccion de datos personales
     */
    getEstadoPerfil(): Observable<any> {
        return this.http.get(this.settings.URL_BASE + 'usuario/perfil/estado');
    }
    
    /**
     * Recupera los mensajes por defecto
     */
    getMensajesPorDefecto(): Observable<any> {
        return this.http.get(this.settings.URL_BASE + 'usuario/mensajes');
    }

}

export interface Register {

    username: number;
    password: string;
    email: string;
    fechaNacimiento: string;
    telefono: string;
    aplicacionId: string;

}
