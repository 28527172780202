import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AlertService {

  constructor(public alert: AlertController) {}

  /**
   * Muestra un mensaje informativo en pantalla
   * @param title titulo del alert
   * @param message body del alert
   * @param btnMsg texto de boton para cerrar alert
   */
  async showMessage(title: string, message: string, btnMsg: string = 'Aceptar') {
    const alert = await this.alert.create({
      message,
      header: title,
      buttons: [
        {
          text: btnMsg,
          handler: () => {
            console.log('Alert info aceptado');
          },
        },
      ],
      cssClass: 'custom-alert'
    });
    alert.present();
  }
}
