import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    // URL base para la comunicacion de servicios
    public URL_BASE = environment.apiUrl;

    // nombre de la app
    public APP_NAME = 'Credi Agil';

    // identificador de la app
    private APP_ID = 'py.com.procesa.crediagilapp';

    // version de la app
    public APP_VERSION = '1.0.0';


    getAppId() {
        return this.APP_ID;
    }

}
