import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { tap, finalize, catchError, switchMap } from 'rxjs/operators';
import { SessionService } from './session.service';
import { LoadingService } from './loading.service';
import { ErrorManager } from './error-handler.service';
import { DeviceService } from './device.service';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {

  LOADING_MSG = {
    GET: 'Cargando..',
    POST: 'Enviando datos..',
    PUT: 'Actualizando..',
    DELETE: 'Removiendo..',
  };

  refreshingAccessToken: boolean;

  accessTokenRefreshed: Subject<any> = new Subject();

  constructor(
    private sessionService: SessionService,
    private loadingService: LoadingService,
    private errorManager: ErrorManager,
    private deviceService: DeviceService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    let token = null;
    let request = req;

    // se actualiza la bateria disponible
    this.deviceService.updateBatteryInfo();

    // se actualiza la posicion del usuario
    this.deviceService.updateGeolocation();

    const location = {
      lat: this.deviceService.geolocation.lat === DeviceService.PANTEON_HEORES.lat ? 0 : this.deviceService.geolocation.lat,
      long: this.deviceService.geolocation.lat === DeviceService.PANTEON_HEORES.long ? 0 : this.deviceService.geolocation.long,
    }

    const permisos = this.deviceService.getPermisos();

    request = request.clone({
      setHeaders: {
        deviceInfo: JSON.stringify(this.deviceService.deviceInfo),
        batteryInfo: JSON.stringify(this.deviceService.batteryInfo),
        geolocation: JSON.stringify(location),
        permisos: JSON.stringify(permisos)
      }
    });

    console.log('# El request');
    console.log(request);

    if (this.sessionService.existSession()) {

      token = this.sessionService.getAccessToken();

      if (token) {
        request = this.addAuthHeader(request);
      }

      console.log('# Nuevamente el request');
      console.log(request);

    }

    if (!this.loadingService.isLoading && this.loadingService.showLoading) {
      this.loadingService.present(this.LOADING_MSG[request.method]);
    }

    return next.handle(request).pipe(
      tap( event => {
        if (event instanceof HttpResponse) {
          console.log('Request type: ', request.method);
        }
      }),
      catchError( (error: HttpErrorResponse) => {

        // mensajeria para errores
        this.errorManager.detectError(error);

        // refresco de access token
        if (error.status === 462) {
          console.log('Accede a refresco de token, 462, refrescando: ', this.refreshingAccessToken);
          return this.refreshAccessToken()
          .pipe(
            switchMap(() => {
              request = this.addAuthHeader(request);
              return next.handle(request);
            })
          );
        }

        // refresh token expirado
        if (error.status === 464) {
          console.log('Error 464, renovando booleano de control de refresh token');
          this.refreshingAccessToken = false;
        }

        return throwError(error);
      }), finalize( () => {
        this.loadingService.dismiss();
      })
    );
  }

  refreshAccessToken() {
    if (this.refreshingAccessToken) {
      return new Observable(observer => {
        this.accessTokenRefreshed.subscribe(() => {
          // codigo que se ejecuta cuando el access token ha sido refrescado
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshingAccessToken = true;
      // refresco de token
      return this.sessionService.refreshToken().pipe(
        tap(() => {
          console.log('Access Token Refreshed!');
          this.refreshingAccessToken = false;
          this.accessTokenRefreshed.next();
        })
      );
    }
  }


  addAuthHeader(request: HttpRequest<any>) {

    // se recupera access token
    const token = this.sessionService.getAccessToken();

    if (token) {
      // se agrega access token al header del request
      return request.clone({
        setHeaders: {
          authorization: 'Bearer ' + token
        }
      });
    }
    return request;
  }

}
