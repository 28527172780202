import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { PerfilUsuarioPage } from './pages/perfil-usuario/perfil-usuario.page';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'bienvenido-slide',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    // outlet: 'outlet-login'
  },
  {
    path: 'registro',
    loadChildren: () => import('./pages/registro/registro.module').then( m => m.RegistroPageModule),
    // outlet: 'outlet-menu'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule),
    // outlet: 'outlet-menu'
  },
  {
    path: 'recuperar-cuenta',
    loadChildren: () => import('./pages/recuperar-cuenta/recuperar-cuenta.module').then( m => m.RecuperarCuentaPageModule),
    // outlet: 'outlet-menu'
  },
  {
    path: 'perfil-usuario',
    loadChildren: () => import('./pages/perfil-usuario/perfil-usuario.module').then( m => m.PerfilUsuarioPageModule),
    // outlet: 'outlet-menu'
  },
  {
    path: 'bienvenido-slide',
    loadChildren: () => import('./pages/bienvenido-slide/bienvenido-slide.module').then( m => m.BienvenidoSlidePageModule),
    // outlet: 'outlet-menu'
  },
  {
    path: 'solicitud-credito',
    loadChildren: () => import('./pages/solicitud-credito/solicitud-credito.module').then( m => m.SolicitudCreditoPageModule),
    // outlet: 'outlet-menu'
  },
  {
    path: 'mapa',
    loadChildren: () => import('./pages/mapa/mapa.module').then( m => m.MapaModule),
    // outlet: 'outlet-menu'
  },
  // {
  //   path: 'inicio',
  //   loadChildren: () => import('./pages/inicio/inicio.module').then( m => m.InicioPageModule)
  // },
  {
    path: 'notificaciones',
    loadChildren: () => import('./pages/notificaciones/notificaciones.module').then( m => m.NotificacionesPageModule),
    // outlet: 'outlet-menu'
  },
  {
    path: 'menu',
    loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule),
    // outlet: 'outlet-menu'
  },
  {
    path: 'solicitudes-credito',
    loadChildren: () => import('./pages/solicitud-credito-list/solicitud-credito-list.module').then( m => m.SolicitudCreditoListPageModule),
    // outlet: 'outlet-menu'
  },
  {
    path: 'estado-cuenta',
    loadChildren: () => import('./pages/estado-cuenta/estado-cuenta.module').then( m => m.EstadoCuentaPageModule)
  },
  {
    path: 'preguntas-frecuentes',
    loadChildren: () => import('./pages/preguntas-frecuentes/preguntas-frecuentes.module').then( m => m.PreguntasFrecuentesPageModule)
  },
  {
    path: 'desarrollo',
    loadChildren: () => import('./pages/desarrollo/desarrollo.module').then( m => m.DesarrolloPageModule)
  },
  {
    path: 'configuracion-dispositivo',
    loadChildren: () => import('./pages/configuracion-dispositivo/configuracion-dispositivo.module').then( m => m.ConfiguracionDispositivoPageModule)
  },
  // {
  //   path: 'verificacion-celular',
  //   loadChildren: () => import('./pages/verificacion-celular/verificacion-celular.module').then( m => m.VerificacionCelularPageModule)
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
