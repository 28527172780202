import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(
        private toastController: ToastController
    ) {}

    /**
     * Muestra un mensaje flotante al usuario
     * @param msg muestra un mensaje al usuario
     * @param duration duracion del mensaje
     * @param cssClass estilo del mensaje
     * @param position ubicacion a mostrar el mensaje
     */
    async showMessage(
        msg: string,
        duration: number = 3,
        cssClass: string = 'success-color',
        position: 'bottom' | 'top' | 'middle' = 'bottom'
    ) {
        const toast = await this.toastController.create({
            message: msg,
            duration: duration * 1000,
            cssClass,
            position
        });

        toast.present();
    }

}