import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor, Plugins, StatusBarStyle } from '@capacitor/core';
import { AlertController, IonRouterOutlet, Platform } from '@ionic/angular';
import { DeviceService } from './services/device.service';
import { NetworkStatusService } from './services/network-status.service';
import { SessionService } from './services/session.service';

// imports para notificaciones
import {
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
} from '@capacitor/core';

import { AlertService } from './services/alert.service';
import { Storage } from '@ionic/storage';
import { Subscription } from 'rxjs';
import { UserService } from './services/user.service';
import { MensajesService } from './services/mensajes.service';

const { PushNotifications } = Plugins;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Mi perfil',
      url: 'perfil-usuario',
      icon: 'person',
      disabled: false
    },
    {
      title: 'Mis cuentas',
      url: '/estado-cuenta',
      icon: 'albums',
      disabled: true
    },
    {
      title: 'Notificaciones',
      url: '/notificaciones',
      icon: 'notifications',
      disabled: false
    },
    {
      title: 'Calculadora de crédito',
      url: '/solicitud-credito',
      icon: 'calculator',
      disabled: true
    },
    {
      title: 'Mis solicitudes',
      url: '/solicitudes-credito',
      icon: 'document',
      disabled: true
    },
    {
      title: 'Datos de desarrollo',
      url: '/desarrollo',
      icon: 'code',
      disabled: false
    },
    {
      title: 'Cómo llegar',
      url: '/mapa',
      icon: 'map',
      disabled: false
    },
    {
      title: 'Clientes referidos',
      url: '/folder/Referidos',
      icon: 'person-add',
      disabled: true
    },
    {
      title: 'Configuración de Dispositivo',
      url: '/configuracion-dispositivo',
      icon: 'settings',
      disabled: false
    },
    {
      title: 'Preguntas frecuentes',
      url: '/preguntas-frecuentes',
      icon: 'help-circle',
      disabled: false
    },
  ];

  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  existSession = false;
  usuario = { nombre: '', apellido: '', fotoPerfil: null, documento: ''};
  @ViewChild(IonRouterOutlet, { static: false }) routerOutlet: IonRouterOutlet;
  backSuscription: Subscription;

  backPressed = false;

  constructor(
    private platform: Platform,
    private sessionService: SessionService,
    private router: Router,
    private alertService: AlertService,
    private storage: Storage,
    // se inicia instancia para controlar estado de conexion
    private networkStatus: NetworkStatusService,
    // se inicia instancia para recuperar datos del dispositivo
    private deviceService: DeviceService,
    private alertController: AlertController,
    private userService: UserService,
    private msgService: MensajesService,
  ) {
    this.initializeApp();
  }

  async initializeApp() {

    console.log('Inicializando aplicacion');

    const { SplashScreen, StatusBar } = Plugins;
    try {

      await SplashScreen.hide();
      await StatusBar.setStyle({ style: StatusBarStyle.Light });
      if (this.platform.is('android')) {
        StatusBar.setBackgroundColor({ color: '#CDCDCD' });
      }
    } catch (err) {
      console.log('This is normal in a browser', err);
    }

    this.backSuscription = this.platform.backButton.subscribeWithPriority(0, async() => {

      if ((this.router.url === '/menu/inicio' || this.router.url === '/login') && !this.backPressed) {
        console.log('Estoy en el home');
        this.backPressed = true;
        const alert = await this.alertController.create({
          header: 'Cerrar aplicación',
          message: '¿Confirma que desea salir de la aplicación?',
          cssClass: 'custom-alert',
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel'
            },
            {
              text: 'Salir',
              handler: () => {
                navigator['app'].exitApp();
              }
            }
          ]
        });
        alert.onDidDismiss().then( () => {
          this.backPressed = false;
        });
        await alert.present();
      }

    });

  }

  ionViewWillLeave() {

    console.log('Cancelando suscripcion');
    this.backSuscription.unsubscribe();
  }

  async ngOnInit() {

    // await this.sessionService.restoreSessionFromStorage();

    this.msgService.getDiccionarioMsg().subscribe( (data: Array<any>) => {
      data.forEach( m => {
        this.msgService.mensajes[m.key].titulo = m.titulo;
        this.msgService.mensajes[m.key].mensaje = m.mensaje;
      });
    });

    // control de uso por primera vez del usuario
    const openApp = await this.storage.get('openApp').then( data => data);
    console.log(openApp);
    if (openApp) {
      this.router.navigate(['/login']);
    } else {
      this.storage.set('openApp', true);
    }

    this.sessionService.clearMenu.subscribe( clear => {
      if (clear) {
        this.selectedIndex = null;
      }
    });

    this.sessionService.userHasSession.subscribe( exist => {
      console.log('Existe: ', exist);
      this.existSession = exist;
      if (this.existSession === true) {
        this.usuario = this.sessionService.getUsuario();
        console.log('Se recupera el usuario');
        console.log(this.usuario);

        // si no se encuentra el nombre de la persona
        // se usa el numero de cedula
        if (!this.usuario.nombre) {
          this.usuario.nombre = this.usuario.documento;
        }
      }
    });

    this.sessionService.userHasVerfied.subscribe( value => {
      if (value === true) {
        this.appPages.forEach( m => {
          if (m.url === '/solicitud-credito') {
            m.disabled = false;
          }
          if (m.url === '/solicitudes-credito') {
            m.disabled = false;
          }
          if (m.url === '/estado-cuenta') {
            m.disabled = false;
          }
          if (m.url === '/notificaciones') {
            m.url = '/menu/notificaciones'
          }
        });
      } else {
        this.appPages.forEach( m => {
          if (m.url === '/solicitud-credito') {
            m.disabled = true;
          }
          if (m.url === '/solicitudes-credito') {
            m.disabled = true;
          }
          if (m.url === '/estado-cuenta') {
            m.disabled = true;
          }
          if (m.url === '/menu/notificaciones') {
            m.url = '/notificaciones'
          }
        });
      }
    });

    // actualiza el estado de los permisos
    await this.deviceService.updatePermissions();

    if (Capacitor.getPlatform() === 'web') {
      return;
    }

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermission().then(result => {
      if (result.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    PushNotifications.addListener(
      'registration',
      (token: PushNotificationToken) => {
        console.log('Registro a notificaciones exitoso', token);
        this.sessionService.registerId = token.value;
        // alert('Push registration success, token: ' + token.value);
      },
    );

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error al registrarse a notificaciones', error);
      // alert('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotification) => {
        console.log('pushNotificationReceived');
        console.log(notification);
        this.alertService.showMessage(notification.data.title, notification.data.body);

        switch (notification.data.servicio) {
          // se acaba de verificar el perfil del usuario
          case '/verificacion':
            this.sessionService.userHasVerfied.next(true);
            if (this.sessionService.userHasSession.value) {
              this.router.navigate(['/menu'])
            }
            break;
        
          default:
            break;
        }

      },
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (pushNotification: PushNotificationActionPerformed) => {
        console.log('pushNotificationActionPerformed');
        this.alertService.showMessage(pushNotification.notification.data.title, pushNotification.notification.data.body);
        
        switch (pushNotification.notification.data.servicio) {
          // se acaba de verificar el perfil del usuario
          case '/verificacion':
            this.sessionService.userHasVerfied.next(true);
            this.router.navigate(['/menu']);
            break;
        
          default:
            break;
        }

      },
    );


    await this.deviceService.updatePermissions();

  }

  goToPage(url: any) {
    this.router.navigate([url]);
  }

  logout() {
    this.sessionService.clearStorage();
    // no se requiere control de sesion de usuario
    this.sessionService.userHasSession.next(false);
    // no se requiere control de usuario verificado
    this.sessionService.userHasVerfied.next(false);
    // no se requiere control de actualizacion de estado de perfil
    this.userService.updateProfileStatus.next(false);
    this.router.navigate(['/login']);
  }

}
