import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastService } from './toast.service';
import { SessionService } from './session.service';
import { Capacitor } from '@capacitor/core';
import { NetworkStatusService } from './network-status.service';
import { AlertService } from './alert.service';
import { MensajesService } from './mensajes.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorManager {

  constructor(
    private router: Router,
    private toastService: ToastService,
    private sessionService: SessionService,
    private networkStatus: NetworkStatusService,
    private alertService: AlertService,
    private mensajesSvc: MensajesService,
  ) {}

  detectError(
    errorResponse: HttpErrorResponse | ErrorEvent | any
  ) {
    console.log(errorResponse);
    console.log(Capacitor.getPlatform(), errorResponse.status);
    // control de internet
    if (Capacitor.getPlatform() === 'web') {
      if (!navigator.onLine || errorResponse.status === 0) {
        this.toastService.showMessage(this.mensajesSvc.mensajes.noInternetMsg.mensaje, 2, 'warning-color');
        return;
      }
    } else {
      if (!this.networkStatus.existConnection || errorResponse.status === 0) {
        this.toastService.showMessage(this.mensajesSvc.mensajes.noInternetMsg.mensaje, 2, 'warning-color');
        return;
      }
    }

    if (errorResponse instanceof HttpErrorResponse) {

      // sesion expirada
      if (errorResponse.status === 462) {
        // no se muestra mensaje, se refresca por debajo desde el interceptor
      }

      // refresh token expirado, se necesita volver a iniciar sesión
      // se muestra mensaje del tipo alert
      if (errorResponse.status === 464) {
        this.alertService.showMessage(this.mensajesSvc.mensajes.sesionExpiradaMsg.titulo, this.mensajesSvc.mensajes.sesionExpiradaMsg.mensaje, 'Aceptar');
        this.sessionService.clearStorage();
        this.router.navigate(['/login']);
      }

      // error del BE contemplado
      if (errorResponse.error.message) {

        if (errorResponse.error.messageType === 'toast') {
          this.toastService.showMessage(
            errorResponse.error.message,
            errorResponse.error.duration,
            errorResponse.error.cssClass,
            errorResponse.error.position
          );
        }

        if (errorResponse.error.messageType === 'alert'){
          this.alertService.showMessage('Advertencia', errorResponse.error.message);
        }

      }

      // error del BE no contemplado
      if (!errorResponse.error.message) {
        console.log('El error no fue tipado con ALERT o TOAST');
        this.toastService.showMessage(this.mensajesSvc.mensajes.inconvenienteMsg.mensaje, 3, 'error-color');
        return;
      }

    }
  }
}
