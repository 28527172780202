import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsService } from './settings.service';

@Injectable({
    providedIn: 'root'
})
export class MensajesService {

    mensajes = {

        datosPersonalesMsg: {
            titulo: 'Datos Personales',
            mensaje: 'Bienvenido a tus datos de perfil, para una mejor experiencia, te invitamos a cargar tus datos personales',
        },
        datosLaboralesMsg: {
            titulo: 'Datos Laborales',
            mensaje: 'Casi listo!, te invitamos a cargar tus Datos Laborales para que tengas la mejor experiencia de usuario',
        },
        documentoMsg: {
            titulo: 'Documento',
            mensaje: 'Para que podamos validar tu perfil y obtengas la mejor experiencia, te invitamos a cargar los detalles de tu Documento',
        },
        direccionParticularMsg: {
            titulo: 'Dirección Particular',
            mensaje: 'Para continuar con la carga de tu perfil, te invitamos a cargar tu Dirección Particular',
        },
        direccionLaboralMsg: {
            titulo: 'Dirección Laboral',
            mensaje: 'Para continuar con la carga de tu perfil, te invitamos a cargar tu Dirección Laboral',
        },
        telefonoLaboralMsg: {
            titulo: 'Teléfono Laboral',
            mensaje: 'Para que puedas aprovechar al máximo nuestro servicios, te invitamos a cargar tu número de Teléfono Laboral',
        },
        telefonoParticularMsg: {
            titulo: 'Teléfono Particular',
            mensaje: 'Para que puedas aprovechar al máximo nuestro servicios, te invitamos a cargar tu número de Teléfono Particular',
        },
        legajoMsg: {
            titulo: 'Legajo',
            mensaje: 'Se requiere la carga de un documento',
        },
        noInternetMsg: {
            titulo: '',
            mensaje: 'Por favor revisa tu conexión a internet',
        },
        sesionExpiradaMsg: {
            titulo: 'Sesión finalizada',
            mensaje: 'Su sesión ha expirado, para continuar usando la aplicación por favor vuelva a iniciar sesión',
        },
        inconvenienteMsg: {
            titulo: '',
            mensaje: 'Disculpe, hemos tenido un inconveniente al realizar operación, intente más tarde',
        },
        permisoGpsMsg: {
            titulo: 'Permisos de GPS',
            mensaje: 'Para utilizar esta función asegurate de conceder permisos a la aplicación, verificá los permisos en los ajustes de tu dispositivo.'
        },
        activarGpsMsg: {
            titulo: 'Activar GPS',
            mensaje: 'Asegurate de activar el GPS para que puedas ver tu ubicación'
        },
        guardadoExitosoMsg: {
            titulo: 'Operación exitosa',
            mensaje: 'Sus datos han sido guardados con éxito'
        },
        actualizacionExitosaMsg: {
            titulo: 'Operación exitosa',
            mensaje: 'Sus datos han sido actualizados con éxito'
        },
        camposRequeridosMsg: {
            titulo: '',
            mensaje: 'Favor verificar campos requeridos'
        },
        salarioFormatoMsg: {
            titulo: '',
            mensaje: 'El campo salario solo acepta puntos para separar miles'
        },
        tipoViviendaRequeridoMsg: {
            titulo: '',
            mensaje: 'Se requiere la carga del campo Tipo Vivienda'
        },
        menorDeEdadMsg: {
            titulo: '',
            mensaje: 'Debe tener 18 años o más'
        },
        seleccionarDireccionMsg: {
            titulo: '',
            mensaje: 'Seleccione el tipo de dirección'
        },
        seleccionarTipoDireccionMsg: {
            titulo: '',
            mensaje: 'Seleccione el Tipo de Dirección'
        },
        seleccionarDepartamentoMsg: {
            titulo: '',
            mensaje: 'Seleccione el Departamento'
        },
        seleccionarCiudadMsg: {
            titulo: '',
            mensaje: 'Seleccione la Ciudad'
        },
        claveMinMsg: {
            titulo: '',
            mensaje: 'La clave debe tener al menos 6 caracteres'
        },
        funcionalidadQr: {
            titulo: '',
            mensaje: 'Próximamente podrás realizar operaciones de pago con QR!',
        },
        aceptarTerminosMsg: {
            titulo: '',
            mensaje: 'Para registrarse debe aceptar los términos y condiciones'
        },
        claveFormatoMsg: {
            titulo: 'Validación de clave',
            mensaje: 'La clave debe estar compuesta por letras mayúsculas, minúsculas y números'
        },
        claveFormatoVerificarMsg: {
            titulo: 'Formato de clave',
            mensaje: 'Verifique sus campos de clave'
        },
        registroExitosoMsg: {
            titulo: 'Registro exitoso',
            mensaje: `¡Casi listo! Para terminar tu activación necesitamos que nos valides tus datos.
            Elegí por qué medio querés validar.`
        },
        registroNoActivoMsg: {
            titulo: 'Aviso Importante',
            mensaje: `Se creó tu cuenta pero no se pudo activar. Para hacerlo más tarde, sólo tenés
            que iniciar sesión con la contraseña que ya creaste`
        },
        emailActivacionMsg: {
            titulo: 'Email de activación',
            mensaje: 'Te mandamos un email de activación a tu correo, verifica tu bandeja de entrada y accede al enlace'
        },
        funcionProximamenteMsg: {
            titulo: 'Atención',
            mensaje: 'Esta función estará disponible próximamente'
        },
        activarMasTardeMsg: {
            titulo: '',
            mensaje: 'Activar más tarde'
        },
        solicitudConfirmacion1Msg: {
            titulo: '',
            mensaje: '¿Confirma que desea solicitar '
        },
        solicitudConfirmacion2Msg: {
            titulo: '',
            mensaje: 'a un plazo de'
        },
        solicitudConfirmacion3Msg: {
            titulo: '',
            mensaje: 'meses'
        },
        solicitudCreditoMsg: {
            titulo: 'Solicitud de Crédito',
            mensaje: 'Se ha enviado tu solicitud de crédito, podras visualizar el estado de la solicitud desde el listado de solicitudes'
        },
        activarCuentaMasTardeMsg: {
            titulo: 'Aviso importante',
            mensaje: `Se creó tu cuenta pero no se pudo activar. Para hacerlo más tarde, sólo tenés que iniciar sesión con
              la contraseña que ya creaste`
        },
        envioEmailRecuperacionMsg: {
            titulo: 'Recuperación de clave',
            mensaje: 'Hemos enviado un correo de recuperación a '
        },
        noHayCambiosMsg: {
            titulo: 'Importante',
            mensaje: 'No se detectaron cambios'
        },
        bienvenidoMsg: {
            titulo: '',
            mensaje: 'Bienvenido'
        },
        envioSmsMsg: {         
            titulo: '',
            mensaje: 'SMS de activación enviado'
        },
        verificacionExitosaSmsMsg: {         
            titulo: '',
            mensaje: 'Verificación de número exitoso'
        },

    };

    constructor(
        private http: HttpClient,
        private settings: SettingsService,
    ) {

    }

    /**
     * Retorna los mensajes por defecto
     */
     getDiccionarioMsg(): Observable<any> {
        return this.http.get(this.settings.URL_BASE + `aplicacion/${this.settings.getAppId()}/diccionario/mensajes`);
    }

}