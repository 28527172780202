import { Injectable } from '@angular/core';
import {
  Plugins,
  NetworkStatus,
  PluginListenerHandle,
  Capacitor,
} from '@capacitor/core';

const { Network } = Plugins;

@Injectable({
  providedIn: 'root',
})
export class NetworkStatusService {

  existConnection = true;
  networkStatus: NetworkStatus;
  networkListener: PluginListenerHandle;

  constructor() {

    console.log('Iniciando control de conexión a internet');

    if (Capacitor.getPlatform() !== 'web') {
        this.networkListener = Network.addListener(
          'networkStatusChange',
          (status) => {
            console.log('Cambio de status: ', status);
            this.networkStatus = status;
            if (!this.networkStatus.connected) {
              this.existConnection = false;
            } else {
              this.existConnection = true;
            }
          }
        );
    }
  }
}
